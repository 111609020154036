/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Mulish:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;1,400&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/brands.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/fontawesome.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/solid.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/regular.min.css');

/* Global css */

* {
  font-family: 'Mulish', sans-serif;
}

p {
  font-family: 'Mulish', sans-serif;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.font-roboto {
  font-family: Roboto;
}

.font-nunito {
  font-family: Nunito;
}

.font-poppins {
  font-family: Poppins;
}

/* Global css ends */

/* animations css */

.slide-in-fwd-bottom {
  /* -webkit-animation: slide-in-fwd-bottom 0.5s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
	animation: slide-in-fwd-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s
		both; */

  transform: translateZ(-3.5rem) translateY(2rem);
  opacity: 0;
}

.slide-in-fwd-right {
  /* -webkit-animation: slide-in-fwd-right 0.5s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
	animation: slide-in-fwd-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s
		both; */
  transform: translateZ(3.5rem) translateX(2.5rem);
  opacity: 0;
}

.slide-in-fwd-top {
  /* -webkit-animation: slide-in-fwd-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
		0.5s both;
	animation: slide-in-fwd-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s
		both; */

  transform: translateZ(-3.5rem) translateY(-2rem);
  opacity: 0;
}

.slide-in-fwd-left {
  /* -webkit-animation: slide-in-fwd-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
		0.5s both;
	animation: slide-in-fwd-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s
		both; */

  transform: translateZ(-3.5rem) translateX(-2.5rem);
  opacity: 0;
}

@-webkit-keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-3.5rem) translateX(-2.5rem);
    transform: translateZ(-3.5rem) translateX(-2.5rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-3.5rem) translateX(-2.5rem);
    transform: translateZ(-3.5rem) translateX(-2.5rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-3.5rem) translateY(2rem);
    transform: translateZ(-3.5rem) translateY(2rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-3.5rem) translateY(2rem);
    transform: translateZ(-3.5rem) translateY(2rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(3.5rem) translateX(2.5rem);
    transform: translateZ(3.5rem) translateX(2.5rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(3.5rem) translateX(2.5rem);
    transform: translateZ(3.5rem) translateX(2.5rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-fwd-top {
  0% {
    -webkit-transform: translateZ(-3.5rem) translateY(-2rem);
    transform: translateZ(-3.5rem) translateY(-2rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-top {
  0% {
    -webkit-transform: translateZ(-3.5rem) translateY(-2rem);
    transform: translateZ(-3.5rem) translateY(-2rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.fade-animation {
  opacity: 0;
  transition: all 0.3s;
}

/* animations css ends */
