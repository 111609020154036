.mail-checks-data {
  background-color: #fafafa;
  height: 148px;
}
.data-heading {
  color: #555555;
  font-size: 14px;
  font-weight: 600px;
}
.data-value {
  color: #434343;
  font-weight: 700px;
}
.ant-drawer .ant-drawer-content-wrapper {
  margin-top: 80px;
}

.ant-drawer-body {
  padding-top: 0px !important;
}
