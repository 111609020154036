/* .custom-status .ant-select {
  margin-bottom: 0px !important;
}

.custom-status .ant-select-single .ant-select-selector {
  margin-bottom: 0px !important;
  border-radius: inherit !important;
  border: inherit !important;
  font-size: 10px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500;
}

.custom-status .ant-select-selector {
  background-color: inherit !important;
  color: inherit !important;
} */

.ant-select.status-select .ant-select-selector {
  margin-bottom: 0;
  border: inherit;
  background-color: inherit;
  color: inherit !important;
}

.ant-select.status-select .ant-select-selector .ant-select-selection-item {
  font-size: 10px;
  color: inherit;
  font-weight: 500;
  font-family: Roboto;
}

.ant-select.status-select:hover .ant-select-selector {
  border-color: inherit !important;
}

.ant-select.status-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 10px;
  color: #555;
  font-weight: 600;
  font-family: Roboto;
}

.ant-select-selector {
  height: auto !important;
  border: 0px;
}

.ant-select .ant-select-selection-placeholder {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: #9d9d9d;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: #9d9d9d;
  font-family: Roboto;
  font-size: 12px;
}
