.preview-analytics-table-heading {
  color: rgba(67, 67, 67, 1);
}

.preview-container {
  background-color: rgba(250, 250, 250, 1);
}

.format-types-checkbox
  .ant-checkbox-group
  .ant-checkbox-group-item
  .ant-checkbox
  + span {
  font-weight: 400px;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
  font-family: Roboto;
}

.format-types-checkbox
  .ant-checkbox-group
  .ant-checkbox-group-item
  .ant-checkbox {
  border: 0px;
}

.format-types-checkbox
  .ant-checkbox-group
  .ant-checkbox-group-item
  .ant-checkbox
  .ant-checkbox-inner {
  border: 1.5px solid #c4c4c4;
  border-radius: 2px;
}

.format-types-checkbox .ant-checkbox-group .ant-checkbox-group-item {
  align-items: center;
  padding: 6px 8px;
}

.data-to-export-checkbox
  .ant-checkbox-group
  .ant-checkbox-group-item
  .ant-checkbox
  + span {
  font-weight: 400px;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
  font-family: Roboto;
}
.data-to-export-checkbox
  .ant-checkbox-group
  .ant-checkbox-group-item
  .ant-checkbox {
  border: 0px;
}

.data-to-export-checkbox
  .ant-checkbox-group
  .ant-checkbox-group-item
  .ant-checkbox
  .ant-checkbox-inner {
  border: 1.5px solid #c4c4c4;
  border-radius: 2px;
}

.data-to-export-checkbox .ant-checkbox-group .ant-checkbox-group-item {
  align-items: center;
  padding: 6px 8px;
}
