/* changed name becuase use in forms already */
.formFields {
  /* margin-bottom:0px !important;  */
}
.filter-headings {
  color: #3c3c3c;
  font-size: 14px;
}
.filter-values {
  font-size: 12px;
  color: #555555;
}
.status-border {
  border: 1px solid #c4c4c4;
  border-radius: 2px;
}
.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper,
.ant-picker {
  border: 1px solid #c4c4c4;
  border-radius: 0px;
}

.status-selected {
  background-color: #edf5fc;
  border-color: #3571a7;
}

.status-value {
  color: #3571a7;
}

.displayed-filter {
  border: 1px solid rgba(84, 84, 84, 0.801);
  color: #555555;
  padding: 8px;
  cursor: pointer;
}
