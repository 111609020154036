.formField {
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
}
.passwordDiv {
  position: relative;
}
.generic-input,
.password-input {
  width: 100%;
  position: relative;
}
.ant-picker {
  width: 100%;
}
.ant-input-number-affix-wrapper {
  border-style: none !important;
}
.remove-prefix .ant-input-number-handler-up {
  display: none;
}
.form-field-label {
  color: #434343;
}

.create-check-form-container {
  padding: 24px 28px 24px 28px;
}

.create-check-form-container .ant-input-affix-wrapper,
.ant-picker,
.ant-input,
.ant-select-multiple .ant-select-selector,
.ant-select-single .ant-select-selector {
  border-radius: 0px;
}

.ant-select .ant-select-selection-placeholder {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400px;
  line-height: 14.06px;
}

.create-check-form-container span {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400px;
  color: #555555;
}

.css-1m9pwf3 {
  width: 16px;
  height: 16px;
}

.radioDiv {
  border: 1px solid #c4c4c4;
  padding-left: 12px;
  margin-right: 8px;
  border-radius: 2px;
}

.css-j204z7-MuiFormControlLabel-root {
  margin-left: 0px;
  margin-right: 12px;
}

.regular-check-option-selected {
  border: 1px solid #204464;
  border-radius: 2px;
  cursor: pointer;
}

.regular-check-option-unselected {
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  cursor: pointer;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 13.3px !important;
  height: 13.3px !important;
}
/* styling for base data component starts */

.custom-base-data .ant-picker-input #date::placeholder {
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400px;
  color: #434343;
}

.formField #memo::placeholder {
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400px;
  color: #434343;
}

.formField #amount::placeholder {
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400px;
  color: #434343;
}

.ant-input {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-input-number-handler .ant-input-number-handler-up,
.ant-input-number-handler-down {
  display: none;
}

.ant-input-number-input {
  border-radius: 0;
}

/* styling for base data component ends */
