.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}

.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}
.fs-17 {
  font-size: 17px;
}
.font-roboto {
  font-family: 'Roboto', sans-serif;
}
.font-montserrat {
  font-family: Montserrat;
}

/* Buttons styles */

button.generic-btn {
  width: fit-content;
  font-family: Roboto;
  font-size: 14px;
  padding: 10px 24px;
  height: auto;
  font-weight: 600;
  border-radius: 0rem;
  cursor: pointer;
}

button.generic-btn span {
  font-family: Roboto;
}

button.generic-btn.light,
button.generic-btn.light:hover {
  color: #204464 !important;
  border: 1.5px solid #204464 !important;
  background-color: #fff !important;
}

button.generic-btn.dark,
button.generic-btn:hover {
  background-color: #204464 !important;
  color: #fff !important;
  border: 1px solid #204464 !important;
}

button.generic-btn:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

/* Styles for my checks page starts*/

.checks-heading {
  color: #434343;
}
.import-check-btn {
  border: 1px solid #204464;
  border-radius: 2px;
  background-color: #fafcff;
  color: #204464;
}
.create-check-btn {
  border: 1px solid #204464;
  border-radius: 2px;
  background-color: #204464;
  color: white;
}

.ant-table-tbody > tr:nth-child(even) {
  background-color: #fafafa; /* Color for even rows */
}

.ant-table-tbody > tr:nth-child(odd) {
  background-color: #ffffff; /* Color for odd rows */
}

.custom-header-table .ant-table-thead > tr > th {
  background-color: var(--Light, #edf5fc);
}

table.ant-table .ant-table-thead th {
  font-weight: 700px;
  font-family: Roboto !important;
}

.custom-table .ant-table-tbody {
  font-weight: 700px;
  font-family: Roboto !important;
  font-size: 12px;
  color: var(--colors-greys-600, #555);
}

.ant-table
  .ant-table-thead
  .ant-table-cell
  .ant-table-column-sorters
  .ant-table-column-title {
  font-weight: 700px;
  font-family: Roboto !important;
  font-size: 12px;
}

.ant-table .ant-table-thead .ant-table-cell {
  font-weight: 700px;
  font-family: Roboto !important;
  font-size: 12px;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  font-family: Roboto !important;
  font-size: 12px;
  font-weight: 500px;
  color: #555555;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700px !important;
  font-family: Roboto;
  font-size: 12px;
  color: #204464;
}

.ant-tabs .ant-tabs-tab-btn {
  font-weight: 400px !important;
  color: #9797a6;
  font-family: Roboto;
  font-size: 12px;
}

.profile-pic {
  color: #000000;
}

.ant-layout-header {
  background-color: white !important;
}

.dashboard-header .dashboard-header-inner {
  background-color: #ffffff !important;
}

.divider-line {
  height: 2rem;
  color: #d7d7d7;
}

.operations-on-table {
  color: #7b7b7b;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500px !important;
  border: 0px;
  background-color: #f8fbff;
  padding-left: 0px;
}

.ant-checkbox .ant-checkbox-inner {
  border: 1.5px solid var(--colors-greys-700, #555);
  border-radius: 0px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--colors-white-200, #204464);
}

.ant-table-cell
  .ant-table-cell-row-hover
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background: var(--colors-white-200, #204464);
}

.square-modal .ant-modal-content {
  border-radius: 0;
}

.check-type {
  color: #434343;
}

.check-type-desc {
  color: #9797a6;
}

.select-check-type {
  background: var(--colors-white-200, #fafafa);
  padding: 16px 24px 46px 13px;
}

.ant-modal .ant-modal-close {
  position: absolute;
  top: 32px;
  right: 32px;
}

.ant-modal-close::before,
.ant-modal-close::after {
  top: 16px;
  right: 16px;
}
/* styles for my checks page ends*/

/*Styling for create-checks starts */
.create-check-form-container {
  background-color: #fff;
}

.create-check-form-container span {
  color: #434343;
}

.check-type-btn {
  background-color: white;
}

/* .generic-btn {
   display: flex;
  align-items: center;
  flex-direction: row;
}

.generic-btn svg {
  width: 20px !important;
  height: 20px !important;
} */

.light-variant-btn {
  background-color: white;
  color: #204464;
  border-radius: 2px;
  border: 1px solid #204464;
  box-shadow: 0px 17px 86px 0px rgba(0, 0, 0, 0.04);
}
.dark-variant-btn {
  background-color: #204464;
  color: white;
  border: 0px;
  border-radius: 2px;
}
.dark-variant-btn:disabled {
  background-color: #d9d9d9;
}

/*Styles for modals*/

::placeholder {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  padding-left: 10px;
}

.add-tag-modal-tabs .ant-tabs-nav {
  display: flex;
  width: 100% !important;
}

.add-tag-modal-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100% !important;
}

.add-tag-modal-tabs .ant-tabs-nav-list {
  display: flex !important;
  justify-content: center !important;
}

.add-tag-modal-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab {
  flex: 1;
  justify-content: center;
  margin-right: 0px;
}

.add-tag-modal {
  color: #9d9d9d;
}

.custom-color-picker
  .ant-color-picker-trigger
  .ant-color-picker-color-block
  .ant-color-picker-color-block-inner {
  border-radius: 58px !important;
  width: 14px !important;
  height: 14px !important;
}

.custom-color-picker .ant-color-picker-trigger {
  border: 0px !important;
  box-shadow: none;
}

.custom-color-picker
  .ant-color-picker-trigger
  .ant-popover-open
  .ant-color-picker-trigger-active {
  box-shadow: none;
}

.custom-color-picker .ant-color-picker-trigger .ant-color-picker-color-block {
  background-image: none !important;
  border: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

/* Styles for modals end */

/*Style for antd tables start */

.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #edf5fc;
}

.ant-table-wrapper .ant-table-expand-icon-col {
  width: 0px !important;
}

.ant-table-wrapper tr.ant-table-expanded-row > td {
  background-color: white;
  color: black;
}

/*Styles for antd table ends */

/* styles for import checks starts */

.import-check-form-container {
  background-color: #fff;
  padding: 36px;
}

.import-checks-data-container {
  background-color: #edf5fc;
}

.import-checks-data-container .circle {
  background-color: #4796df;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  border-radius: 50%;
  color: white;
}

.import-checks-data-container .steps {
  color: #9797a6;
}

.instructions {
  color: #434343;
}

.upload-file-block {
  background-color: #edf5fc;
  width: 70%;
  border-radius: 5px;
  border: 1px dashed #204464;
  border-spacing: 16px;
  margin: 100px 0px 120px 0px;
}

.ant-upload-wrapper {
  background-color: #edf5fc;
  width: 70%;
  border-radius: 5px;
  border: 0px;
  border-spacing: 16px;
  margin: 100px 0px 10px 0px;
  border-spacing: 0px;
}

.upload-list-item {
  width: 70%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 20px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
  display: none;
}

.filename {
  color: #434343;
}

.uploaded {
  color: #9d9d9d;
}

.upload-file-or {
  color: #9d9d9d;
}

.upload-file-option {
  color: #555555;
}

.delete-icon {
  cursor: pointer;
}
/* style for import checks ends */

/* Styles for preview table starts */

.preview-table .ant-table .ant-table-thead > tr > th {
  background-color: #f4f4f4;
}

.preview-table
  .ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0px;
}

.preview-table
  .ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0px;
}

.preview-table .ant-table .ant-table-thead .ant-table-cell {
  font-weight: 400;
  color: #555555;
  font-family: Roboto;
  font-size: 10.97px;
}

.preview-table .ant-table .ant-table-tbody .ant-table-cell {
  font-weight: 400px;
  font-size: 9px;
  font-family: Roboto;
  line-height: 10.55px;
  color: #555555;
}

.preview-table .ant-table .ant-table-tbody > tr > td {
  background-color: #ffffff;
}

.preview-table
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container {
  border: 0.91px solid #e3e3e3;
  border-start-start-radius: 0px !important;
  border-radius: 0px;
}

/* Styles for preview table ends */

.status-dropdown .ant-select .ant-select-selector {
  border-radius: 2px;
}

.status-dropdown .ant-select .ant-select-selector .ant-select-selection-item {
  font-family: Roboto !important;
  font-weight: 600;
  font-size: 10px;
}
/* Context Menu styles */

.ant-dropdown.generic-context-menu-overlay ul.ant-dropdown-menu {
  padding: 0;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 2px 10px 0px rgba(133, 133, 133, 0.13);
}

.ant-dropdown.generic-context-menu-overlay
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item {
  border-bottom: 1px solid #ebebeb;
  padding: 0.85rem 1rem;
}

.ant-dropdown.generic-context-menu-overlay
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item:last-child {
  border: none;
}

.ant-dropdown.generic-context-menu-overlay
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item
  .context-menu-option
  svg.context-menu-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #9d9d9d;
  margin-right: 8px;
}

/* Context menu styles ends */

/* Dropdown button styles */
.generic-dropdown-btn.ant-btn.ant-dropdown-trigger {
  height: auto;
  padding: 8px 20px;
  display: flex;
  align-items: center;
}

.generic-dropdown-btn.ant-btn.ant-dropdown-trigger svg {
  width: 20px !important;
  height: 20px !important;
}

.generic-dropdown-btn.ant-btn.ant-dropdown-trigger.dark {
  background-color: #204464;
  border: 1px solid #204464;
}

.generic-dropdown-btn.ant-btn.ant-dropdown-trigger.dark svg {
  fill: #fff;
}

.generic-dropdown-btn.ant-btn.ant-dropdown-trigger.dark .btn-title {
  color: #fff;
}

.generic-dropdown-btn.ant-btn.ant-dropdown-trigger.light {
  border: 1px solid #204464;
  background-color: #fff;
}

.generic-dropdown-btn.ant-btn.ant-dropdown-trigger.light svg {
  fill: #204464;
}

.generic-dropdown-btn.ant-btn.ant-dropdown-trigger.light .btn-title {
  color: #204464;
}

/* Dropdown button styles ends*/

.nested-table-row {
  background-color: white !important;
}
.display-none {
  display: none;
}
.display-unset {
  display: unset;
}
