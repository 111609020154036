.fontSize-5 {
  font-size: 5px !important;
}
.fontSize-10 {
  font-size: 10px;
}
.ant-divider-horizontal {
  margin: 4px 0px;
}
/* .pay-to-order-divider .ant-divider-horizontal{
    width:'80%';
    min-width: '80%';
} */
.showPreviewContainer .ant-divider-horizontal {
  color: #e1e1e1;
}
.subTextColor {
  color: #555555;
}
.textColor {
  color: #262626;
}
.stampContentColor {
  color: #434343;
}
.dateBottomBorder {
  border-bottom: 1px solid #555555;
}
