/* /* Containers styles */

/* .main-container {
	height: 100vh;
	overflow: auto;
} */

.app-main-container {
  height: 100vh;
  overflow: auto;
}

.app-content-container {
  margin: 1.5rem 1rem 1rem 5rem;
  padding: 0rem 1rem 0;
  overflow: auto;
  max-height: 100vh;
}

.app-layout-content {
}

.app-inner-layout {
  background-color: #f8fbff !important;
}

.app-content-container::-webkit-scrollbar {
  display: none;
}

/* Container styles ends */

/* component css */

button.common-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.65rem 1.5rem;
  border-radius: 0.25rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: ease-out 300ms;
  font-size: 0.875;
}

button.common-btn span.icon {
  transition: ease-out 300ms;
}

button.common-btn.animation-right:hover span.icon {
  transform: translateX(0.25rem);
}

button.common-btn.animation-left:hover span.icon {
  transform: translateX(-0.25rem);
}

.blue-btn {
  background-color: #204464;
  color: #fff;
  border: 1px solid #204464;
  transition: all 0.3s;
}

.blue-btn:hover {
  background: #204464;
}

button.common-btn.white-btn {
  border: none;
  color: #204464;
  background-color: #fff;
  transition: linear 300ms;
}

.white-btn:hover {
  border: 2px solid #204464;
}

h1.dashboard-primary-header {
  font-family: Roboto;
  font-weight: 500;
  color: #434343;
}

/* utility classes */

.fs-48 {
  font-size: 3rem;
}

.fs-60 {
  font-size: 3.75rem;
}

.fs-72 {
  font-size: 6rem;
}
.pointer {
  cursor: pointer;
}
