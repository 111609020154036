.app-header .app-logo img {
  height: 2.5rem;
  width: auto;
}

.header-nav {
  margin-left: 7rem;
}

.header-nav nav a {
  text-decoration: none;
  color: #747474;
}

.header-nav-links::after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  transition: linear 0.3s;
}

.header-nav-links:hover::after {
  width: 100%;
}

.notification-icon .indicator {
  pointer-events: none;
}

.notification-icon .indicator .indicate {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #3253cb;
  border-radius: 100%;
  top: 2px;
  left: -1px;
  -webkit-animation: wave 1s infinite;
  animation: wave 1s infinite;
  box-sizing: border-box;
  box-shadow: 0 0 0 0 rgba(50, 83, 203, 0.3);
}

.notification-icon .indicator .indicate-border {
  position: absolute;
  background: transparent;
  top: 2px;
  left: -1px;
  right: 25%;
  -webkit-animation: waveLine 1s infinite;
  animation: waveLine 1s infinite;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border: 0.5px solid rgb(50, 83, 203);
  border-radius: 100%;
  transform-origin: center;
}

@keyframes wave {
  60% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.05);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

@keyframes waveLine {
  0% {
    transform: scale(1);
  }
  100% {
    height: 8px;
    border: 0.4px solid #3253cb;
    transform: scale(3);
    opacity: 0;
  }
}

.dashboard-header .app-brand-logo svg {
  width: 8rem;
  height: auto;
}

.dashboard-header-inner {
  height: inherit;
}

.header-search {
  border-radius: 0;
}

.header-search input {
  border: 0;
  border-radius: 0;
}

.header-search input:focus {
  box-shadow: none;
}

.header-search input::placeholder {
  font-family: Roboto;
  color: #9797a6;
  font-weight: 500;
  font-size: 1rem;
}

.header-search span.ant-input-group-addon {
  background-color: #fff;
  border-radius: 0;
  border: none;
}

.header-search span.ant-input-group-addon button {
  border: 0 !important;
  height: auto;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: none;
  border-radius: 0;
}

.header-search span.ant-input-group-addon button svg {
  fill: #204464;
}

.app-header-dropdown {
  padding: 10px 20px;
  font-family: Roboto;
  font-weight: 500;
}

.app-header-dropdown span {
  font-family: Roboto;
}

/* Header css ends */

/* Footer css */

footer {
  background: #f9f9fb;
  padding: 2.5rem 0;
  position: relative;
  z-index: 1;
}

.footer-upper {
  border-bottom: 1px solid #b0b3f9;
  padding: 3rem 0;
}

.footer-upper .social span.icon {
  margin-right: 0.8rem;
}

.footer-upper .social a,
.footer-upper .social p {
  color: #5c5e9b;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.footer-upper .social svg path {
  fill: #5c5e9b;
}

.footer-middle {
  padding: 3rem 0;
  border-bottom: 1px solid #b0b3f9;
}

.footer-middle .input-group input {
  background: #f8f8f8;
  border-radius: 0.625rem 0 0 0.625rem;
  color: #6e7876;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.875rem 1.5rem;
  width: 25rem;
}

.footer-middle .input-group {
  align-items: center;
  background-color: #d9dbfc;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.footer-middle .input-group input:focus {
  box-shadow: none;
  outline: none;
}

.input-group button {
  background: #3253cb;
  border-radius: 0 0.625rem 0.625rem 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  min-width: 140px;
  padding: 0.875rem 2rem;
}

.footer-middle .footer-links .link-head {
  color: #000851;
  font-size: 14px;
  margin-bottom: 1.5rem;
}

.app-footer .footer-middle .footer-links a {
  color: #5c5e9b;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-decoration: none;
  width: fit-content;
  transition: all 0.3s;
  border-radius: 6px;
  position: relative;
}

.app-footer .footer-middle .footer-links a::after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: #5c5e9b;
  transition: linear 0.3s;
}

.app-footer .footer-middle .footer-links a:hover::after {
  width: 100%;
}

.footer-lower p.copyright-txt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #5c5e9b;
}

/* Footer css ends */

/* Auth layout css */

.auth-container {
  position: relative;
}

.auth-banner {
  background-color: #f5f5f5;
  /* padding: 3rem 2rem 0; */
  height: 100vh;
  position: sticky;
  top: 0;
  width: auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: start; */
}
.auth-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.auth-header {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
  margin-top: 5rem;
}

.profile-initials {
  background-color: #3f7568;
  color: #e7feab;
  font-size: 16px;
  padding: 10px;
  border-radius: 6px;
  margin-right: 12px;
}

/* Auth layout css ends */

/* Sidebar styles */

.sider-container {
  top: 0;
  position: absolute;
  height: calc(100vh - 80px);
  left: 0;
  z-index: 1;
}

.sider-container.show {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
}

.sider-container .app-sider {
  cursor: pointer;
  height: inherit;
  padding-top: 1rem;
}

.sider-container .app-sider .sidebar-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
}

.sider-container .app-sider .sidebar-menu .sidebar-menu-bottom :active {
}

.sider-container .app-sider .sidebar-menu .sidebar-menu-top-item {
  font-size: 14px;
  color: #7b7b7b;
  font-weight: 600;
  background-color: #fff;
  font-family: Roboto;
}

.sider-container
  .app-sider
  .sidebar-menu
  .ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sider-container .app-sider .sidebar-menu .sidebar-menu-bottom-item {
  font-family: Roboto;
  font-size: 14px;
  color: #7b7b7b;
  font-weight: 600;
  background-color: #fff;
}

.sider-container .app-sider .sidebar-menu .ant-menu-item {
  height: auto;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sider-container .app-sider .sidebar-menu .ant-menu-submenu {
  margin-top: 10px;
  margin-bottom: 20px;
}

.sider-container
  .app-sider
  .sidebar-menu
  .ant-menu-submenu
  .ant-menu-submenu-title {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sider-container
  .app-sider
  .sidebar-menu
  .ant-menu-submenu
  .ant-menu-sub
  .ant-menu-title-content {
  margin-left: 6px;
}

.sider-container
  .app-sider
  .sidebar-menu
  .ant-menu-submenu
  .ant-menu-submenu-title {
  height: auto;
  line-height: normal;
}

.sider-container.show .app-sider .sidebar-menu .ant-menu-item-selected {
  border-left: 3px solid #3571a7;
  border-right: 0;
}

.sider-container .app-sider .sidebar-menu .ant-menu-item-selected {
  border-right: 3px solid #3571a7;
  border-left: 0;
}

.sidebar-menu .ant-menu-item-selected {
  color: #204464;
  /* border-right: 3px solid #3571A7; */
  background-color: #fff;
  border-radius: 0;
  font-weight: 800;
}

.sider-container.show
  .app-sider
  .sidebar-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: #204464;
  border-left: 3px solid #3571a7;
  background-color: #fff;
  border-radius: 0;
  border-left: 0;
  font-weight: 800;
}

.sider-container
  .app-sider
  .sidebar-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: #204464;
  border-right: 3px solid #3571a7;
  background-color: #fff;
  border-radius: 0;
  border-left: 0;
}

.sider-container .app-sider .sidebar-menu .ant-menu-item-icon {
  height: 20px !important;
  width: 20px !important;
}
/* 
.sider-container
	.app-sider
	.sidebar-menu
	.sidebar-menu-bottom
	.ant-menu-item-icon {
	height: 20px;
	width: 20px;
} */

.app-main-container
  .app-inner-layout
  .sider-container
  .app-sider
  .sidebar-menu
  .sidebar-menu-top
  .sidebar-menu-top-item
  span.ant-menu-title-content {
  font-family: Roboto;
}

.app-main-container .app-inner-layout .sider-container .ant-layout-sider {
  transition:
    all 0.15s,
    background 1s;
}

.app-main-container
  .app-inner-layout
  .sider-container
  .app-sider
  .sidebar-menu
  .ant-menu-submenu-arrow {
  transition:
    transform 2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s;
}

/* .ant-menu-submenu-arrow */
/* ant-menu-item-icon */

/* .sidebar-menu-item .ant-menu-item-icon{
	width: 15px;
	height: 15px;
} */

/* Sidebar styles ends here */
