/* styles for adding group tags */

.group-color-picker-container {
  width: 52px;
  height: 44px;
  position: relative;
}

.group-color-picker {
  position: absolute;
  opacity: 0;
}

.group-color-picker-icon {
  position: absolute;
  z-index: 10;
}

.tag-color-picker-container {
  width: 46px;
  height: 44px;
  position: relative;
}

.group-tag-input {
  position: relative;
  width: 80%;
  height: 44px;
}

.delete-tag {
  width: 52px;
  height: 44px;
}

.add-tag-option {
  color: #262626;
  cursor: pointer;
  line-height: initial;
}

/*Styles for adding single tag*/

.tag-input-container {
  position: relative;
  width: 46px;
  height: 44px;
}

.tag-input {
  height: 44px;
}
