.heading {
  font-weight: 600px;
  font-size: 14px;
  color: #555555;
  margin-bottom: 16px;
  font-family: Roboto;
}

.sub-heading {
  font-weight: 400px;
  font-size: 14px;
  color: #9d9d9d;
  font-family: Roboto;
}

.value {
  font-weight: 600px;
  font-size: 14px;
  color: #555555;
  font-family: Roboto;
}

.details-container {
  padding: 16px;
}

.details-cell {
  margin-right: 36px;
}
