h1.primary-header {
  color: #212121;
}

h1.primary-header span.gradient {
  background: linear-gradient(
    269.9deg,
    #854fdd 21.21%,
    #327dec 70.2%,
    #10ba9b 98.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.hero-section img.banner-img {
  height: 50rem;
}

.hero-section .banner-img-div {
  position: absolute;
  right: -6rem;
  top: -8rem;
}

p.common-txt {
  color: rgba(125, 125, 125, 1);
  line-height: 1.5rem;
  font-size: 1.25rem;
  margin: 1rem 0;
  font-family: Poppins;
  font-weight: 400;
}

h2.section-header {
  color: #212121;
}

h4.gradient-header {
  background: linear-gradient(90deg, #2898ff -4.11%, #8146ff 97.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h6.gradient-header {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h6.gradient-header.gradient-1 {
  background: linear-gradient(90deg, #4eb785 -4.11%, #6d84fc 97.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h6.gradient-header.gradient-2 {
  background: linear-gradient(90deg, #be1089 -4.11%, #f27216 97.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h6.gradient-header.gradient-3 {
  background: linear-gradient(90deg, #3d87a7 -4.11%, #2ec04f 97.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h3.subheader {
  color: #212121;
}

.features .feature {
  font-size: 1rem;
  line-height: 1.25rem;
  color: #757575;
  padding: 0.5rem 0;
}

.features .feature span.icon {
  margin-right: 0.5rem;
}

.products-section .product {
  height: 100%;
}

.products-section .product .card {
  padding: 3rem 2rem 2rem;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 24px 24px 48px rgba(79, 79, 79, 0.03);
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: ease-out 300ms;
}

.products-section .product .card:hover {
  box-shadow: 16px 16px 48px rgba(79, 79, 79, 0.1);
}

.products-section .product .card span.icon {
  transition: ease-out 300ms;
}

.products-section .product .card:hover span.icon {
  margin-left: 8px;
}

.product .card .icon {
  margin-bottom: 1rem;
}

.product .card a {
  text-decoration: none;
  text-transform: uppercase;
  color: #327dec;
  font-weight: bold;
  position: absolute;
  bottom: 1rem;
  display: none;
}

.buisness-inteligence {
  padding: 6rem 0;
  margin-top: 10rem;
}

.buisness-feature {
  width: 80%;
}

.buisness-feature p {
  color: #dbdfeb;
}

.data-security-section .security-card {
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow:
    20px 20px 60px rgba(79, 79, 79, 0.03),
    0px 16px 48px rgba(79, 79, 79, 0.03);
  border-radius: 12px;
  padding: 3rem 4rem;
}

.data-security-section .security-card .security {
  width: 65%;
  margin: 0 auto;
}

.testimonial-section h4 {
  background: linear-gradient(90deg, #288bff -4.11%, #a346ff 97.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.carousel-controllers {
  margin: 3rem 0;
}

.carousel-controllers button.carousel-control-prev,
.carousel-controllers button.carousel-control-next {
  width: auto;
  color: #000;
  font-size: 28px;
}

.carousel-controllers .carousel-indicators {
  margin: 0 1.25rem;
}

.carousel-controllers
  .carousel-indicators
  button.testimonial-carousel-indicator {
  width: 12px;
  height: 12px;
  background: #757575;
  border-radius: 20px;
  opacity: 1;
  border: none;
  margin: 0 12px;
  transition: linear 0.2s;
}

.carousel-controllers
  .carousel-indicators
  button.testimonial-carousel-indicator.active {
  width: 24px;
  background: #3253cb;
}

p.carousel-txt {
  font-size: 1.5rem;
  color: #212121;
  margin: 1.5rem 0 2.5rem;
}

.testimonial-logo {
  width: 60%;
  height: auto;
}

.short-features .feature-name {
  font-family: 'Mulish';
  color: #000;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0);
  box-shadow:
    0px 5px 11px 0px rgba(0, 0, 0, 0.02),
    0px 21px 21px 0px rgba(0, 0, 0, 0.02),
    0px 47px 28px 0px rgba(0, 0, 0, 0.01),
    0px 83px 33px 0px rgba(0, 0, 0, 0),
    0px 129px 36px 0px rgba(0, 0, 0, 0);
  margin-bottom: 1.25rem;
  padding: 1rem 1.25rem;
  border-radius: 10px;
  color: rgba(32, 68, 100, 1);
  width: 17rem;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.choose-us-section .choose-div {
  background-color: #fafafa;
  position: relative;
  height: 28rem;
  padding: 2rem 2rem 0;
}

.choose-us-section .choose-div img {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.choose-div .icon svg {
  width: 3rem;
  height: auto;
  margin-bottom: 2rem;
}

.feature-div {
  background-color: #fafafa;
  padding: 3.75rem 2rem 0;
  border-radius: 1rem;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feature-div img.feature-image {
  width: 100%;
  height: 100%;
}
.start-section .banner-img-div {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  /* right: -6rem; */
  left: 0;
}

.start-section .banner-img-div img {
  width: 100%;
  height: auto;
}

.client-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-logo img {
  height: 8rem;
}
