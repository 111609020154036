.auth-form-div {
  width: 60%;
  margin: 2rem auto 0;
}

.auth-form-div .app-logo {
  height: 20px;
  width: auto;
}

h2.form-header {
  font-family: Roboto !important;
}

p.form-headline {
  font-weight: 500;
  color: #969696;
  font-family: Roboto;
}

.formField {
  margin-bottom: 2rem;
  position: relative;
  /* width: 100%; */
}

.formField label {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  width: fit-content;
  color: #555555;
}

.formField label span.required-field-star {
  color: #f11b1b;
  font-size: 16px;
  position: absolute;
  top: 1px;
  right: -10px;
  font-weight: bold;
}

span.span-required-field-star {
  color: #f11b1b;
  font-size: 16px;
  position: absolute;
  margin-left: 4px;
  margin-top: 2px;
  /* top: 1px;  */
  /* right: -10px; */
  font-weight: bold;
}

.formField btn button.form-btn {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background: #3f7568;
  color: #e7feab;
}

button.form-btn:hover {
  background: #325e54;
}

.formField .generic-input {
  border-radius: 0px;
  border: 1px solid #c8cbcb;
  background: #fff;
  font-size: 12px;
  padding: 7px 10px;
  font-family: Roboto;
}

.formField .generic-input:hover {
  box-shadow: none;
  border-color: #c8cbcb;
}

.formField .generic-input:focus {
  box-shadow: none;
}

.formField input:-webkit-autofill {
  background-color: #fff !important;
}

.errorMsg {
  color: red;
  position: absolute;
  font-size: 10px;
  left: 5px;
  top: 100%;
  font-family: Roboto;
}

.generic-checkbox span.ant-checkbox-inner {
  border: 1.5px solid #555;
  border-radius: 2px !important;
}

.generic-checkbox span.ant-checkbox-inner:hover {
  border-color: #555 !important;
  box-shadow: none !important;
}

.divider span.line {
  width: 35%;
  border-top: 1px solid #c8cbcb;
}

.divider span.txt {
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  color: #969696;
  font-weight: 500;
  padding: 0 8px;
}

.social-login button.social-btn {
  background: #ffffff;
  border: 1px solid #9d9d9d;
  border-radius: 0rem;
  margin-bottom: 10px;
  font-size: 14px;
  color: #000000;
  padding: 0.6rem 1rem;
  width: 100%;
  text-transform: none;
  height: auto;
}

.social-login button.social-btn span.icon {
  width: auto;
  height: auto;
}

.redirect-txt {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #9797a6;
}

.redirect-txt a {
  color: #204464;
  text-decoration: none;
}

.react-tel-input.phone-input input {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 3px;
  font-size: 12px;
  border: 1px solid #c8cbcb;
  border-radius: 0px;
  height: auto;
}

.react-tel-input.phone-input input:hover {
  border-color: #c8cbcb;
}

.react-tel-input.phone-input input:focus {
  border-color: #c8cbcb;
  box-shadow: none;
}

.react-tel-input.phone-input .special-label {
  font-family: 'Inter';
  font-size: 12px;
  color: #969696;
  top: -9px;
  left: 6px;
  transform: scale(0.75);
}

.react-tel-input.phone-input .flag-dropdown,
.react-tel-input.phone-input .flag-dropdown .selected-flag {
  border-radius: 0;
}

input.otpInput {
  text-align: center !important;
  width: 46px !important;
  padding: 5px 8px;
  height: 50px;
  border: 1px solid #c8cbcb;
  border-radius: 4px;
  transition: all 0.3s;
}

input.otpInput::-webkit-inner-spin-button,
input.otpInput::-webkit-outer-spin-button {
  display: none;
}

input.otpInput:focus-visible {
  border-width: 2px;
  border-color: #204464;
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  display: none !important;
}

/* Modules forms */

.app-modal.form-modal {
}

.app-modal.form-modal .modal-dialog {
  min-width: 50vw;
}

.app-modal.form-modal .modal-content {
  border: none;
  border-radius: 12px;
}

.app-modal.form-modal .modal-header {
  padding: 2rem 2.5rem;
}

.app-modal.form-modal .modal-header h3 {
  font-weight: 600;
  font-size: 24px;
  color: #12221f;
  margin: 0;
}

.app-modal.form-modal .modal-header button {
  font-size: 1rem;
}

.app-modal.form-modal .modal-header button:focus {
  box-shadow: none;
}

.app-modal.form-modal .modal-header button:focus-visible {
  box-shadow: none;
  outline: none;
}

.app-modal.form-modal .modal-body {
  padding: 2rem 0;
}

.app-modal.form-modal .modal-body form {
  padding: 0 2.5rem;
}

.app-modal.form-modal .form-tabs {
  margin-bottom: 2rem;
  border-bottom: 1px solid #e7e0ec;
}

.app-modal.form-modal .form-tabs .form-tab {
  font-size: 1rem;
  font-weight: 500;
  color: #1c1b1f;
  width: 100%;
  text-align: center;
}

.app-modal.form-modal .form-tabs .form-tab.active:after {
  content: '';
  display: block;
  width: 70%;
  height: 1px;
  background-color: #3f7568;
  margin: 0 auto;
}

.form-div {
  border: 1px solid #e3e5e8;
  border-radius: 4px;
}

.form-div .input-prefix {
  width: fit-content;
  padding: 0px 15px;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: #757575;
}

.selected-values {
  /* border: 1px solid; */
  position: relative;
}

.ant-select {
  display: block;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  /* line-height: 1.5714285714285714; */
  list-style: none;
  font-family: Roboto;
  position: relative;
  display: block;
  cursor: pointer;
}

.ant-select-selector {
  height: 38px;
}

/* MultiSelectComponent.css */
.custom-select.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #204464;
}

.ant-select-single .ant-select-selector {
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 2rem;
  /* padding: 12px 16px; */
  color: #9d9d9d;
  border-color: #d9d9d9;
  font-size: 14px;
  /* line-height: 1.5714285714285714; */
  list-style: none;
  font-family: Roboto;
  display: flex;
}

.custom-select.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  border-radius: 0px;
}

.ant-tag .ant-tag-close-icon {
  margin-left: 6px;
  color: #323232;
  font-size: 12px;
}

.custom-select.ant-select-multiple .ant-select-selection-overflow {
  display: none !important;
}

.ant-avatar .ant-avatar-string {
  font-family: Roboto;
}
.ant-picker.generic-time-picker {
  border-radius: 0px;
  border: 1px solid #c8cbcb;
  background: #fff;
  padding: 7px 10px;
}

.ant-picker.generic-time-picker.ant-picker-focused {
  box-shadow: none;
}

.ant-picker.generic-time-picker input {
  font-size: 12px;
  color: #555;
}

.ant-picker.generic-date-picker {
  border-radius: 0px;
  border: 1px solid #c8cbcb;
  background: #fff;
  padding: 6.5px 10px;
}

.ant-picker.generic-date-picker.ant-picker-focused {
  box-shadow: none;
}

.ant-picker.generic-date-picker input {
  font-size: 12px;
  color: #555;
}

.ant-picker-input input {
  font-size: 12px;
  color: #555;
  font-family: Roboto;
}

/* Modules forms ends */

/* Extra css */

.list-group-item {
  padding: 5px 10px !important;
}

.list-group-item .form-check-input {
  width: 14px;
  height: 14px;
  border-radius: 3px !important;
}

.list-group-item .form-check-input:focus {
  box-shadow: none;
  outline: none;
  border-color: #325e54;
}

.list-group-item .form-check-input:checked {
  background-size: 14px;
  background-color: #5ea479;
  border-color: #5ea479;
}

.field-prefix {
  padding: 7.7px 14px;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  border-right: 0px;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  color: #717171;
}

/* Extra css ends */

/*  ant design for table starts */
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
  /* border: 1px solid rgb(227, 17, 17) !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  color: #979797;
  background: #f5f5f5;
  font-weight: 700;
  font-family: Roboto;
  /* padding: 7px 8px; */
}

.ant-pagination .ant-pagination-disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  color: #979797;
  background: #f5f5f5;
  font-weight: 700;
  font-family: Roboto;
  padding: 0px 0px;
  /* height: 100%; */
}

.ant-pagination .ant-pagination-item {
  background: none;
  color: #555555;
  border-radius: 0;
  font-weight: 700;
  font-family: Roboto;
}

.ant-pagination .ant-pagination-item-active {
  background: #c6def5;
  color: #555555;
  border-radius: 0;
  font-weight: 700;
  font-family: Roboto;
  border: none;
}

.individual-task-tab.dashboard-tabs .ant-tabs-nav::before {
  display: none !important;
}

.ant-input-affix-wrapper,
.ant-picker {
  border-radius: 0px !important;
}

.ant-picker-input #startDate,
#endDate {
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400px;
}

/* custom designs for input , datepicker, inputNumber componennt ends */

/* Extras */

p.duration-picker-format {
  top: 41px;
  left: 10px;
}

/* Extras css ends */

.task-status-selector > .ant-select-selector {
  padding: 0 !important;
  margin-bottom: 0 !important;
}
