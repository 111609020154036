.ant-select.status-select .ant-select-selector,
.ant-select.status-select .ant-select.selector.ant-select-focused {
  margin-bottom: 0;
  border: inherit;
  background-color: inherit;
  color: inherit !important;
  border-color: inherit !important;
  box-shadow: none !important;
}

.ant-select.status-select .ant-select-selector .ant-select-selection-item {
  font-size: 10px;
  color: inherit;
  font-weight: 500;
  font-family: Roboto;
}

.ant-select.status-select:hover .ant-select-selector {
  border-color: inherit !important;
}

.ant-select.status-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 10px;
  color: #555;
  font-weight: 600;
  font-family: Roboto;
}

.ant-select-selector {
  height: auto !important;
}

.ant-select .ant-select-selection-placeholder {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: #9d9d9d;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: #9d9d9d;
  font-family: Roboto;
  font-size: 12px;
}

.ant-input {
  color: #9d9d9d;
}

.form-control {
  color: #9d9d9d;
}

.ant-select.status-select.no-border .ant-select-selector {
  border: none;
  width: auto;
}

.ant-select-dropdown.status-select-dropdown {
  border-radius: 0;
  background: #fff;
  box-shadow: 1px 2px 10px 0px rgba(133, 133, 133, 0.13);
  padding: 5px 0;
}

.ant-select-dropdown.status-select-dropdown::-webkit-scrollbar {
  display: none;
}

.ant-select-dropdown.status-select-dropdown
  .ant-select-item.ant-select-item-option {
  min-height: auto;
  padding: 7px 8px;
  text-align: center;
  border-radius: 0;
  border-bottom: 1px solid #d9d9d9;
}

.ant-select-dropdown.status-select-dropdown
  .ant-select-item.ant-select-item-option:last-child {
  border: 0;
}

.ant-select-dropdown.status-select-dropdown .ant-select-item-option-content {
  font-size: 10px;
  color: inherit;
  font-weight: 500;
  font-family: Roboto;
}

.ant-select.priority-select {
  border-radius: 100px;
  max-width: 100px;
}

.ant-select.priority-select .ant-select-selector {
  margin-bottom: 0;
  border: inherit;
  background-color: inherit;
  color: inherit;
  font-size: 12px;
  height: auto;
  border-radius: 100px;
  font-family: 'Roboto';
}

.ant-select.priority-select.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  color: inherit;
}

.ant-select.priority-select:hover .ant-select-selector {
  border: inherit !important;
  box-shadow: none !important;
}

.ant-select.ant-select-focused.priority-select .ant-select-selector {
  border: inherit !important;
  box-shadow: none !important;
  color: inherit !important;
}

.ant-select.priority-select
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  color: inherit !important;
}

.ant-select.priority-select.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  color: inherit !important;
}

.status-select.ant-select-
  focused:where(.css-dev-only-do-not- override-1w4v3hc).ant-
  select:not(.ant-select- disabled):not(.ant-select-customize- input):not(
    .ant-pagination-size- changer
  )
  .ant-select-selector {
  box-shadow: none !important;
}

.ant-select.member-select .ant-select-selector {
  border-radius: 0;
  border: 1px solid #d9d9d9;
}

.ant-select.member-select.ant-select-focused .ant-select-selector {
  box-shadow: none;
}

.ant-select.member-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #d9d9d9;
}

.ant-select.member-select .ant-select-selection-placeholder {
  font-size: 12px;
  color: #9d9d9d;
  font-family: Roboto;
}

.ant-select.member-select
  .ant-select-selection-overflow-item
  .ant-select-selection-search
  input {
  font-size: 12px;
  color: #555;
  font-family: Roboto;
}

.ant-select.member-select
  .ant-select-selection-overflow-item:not(
    .ant-select-selection-overflow-item-suffix
  ) {
  display: none;
}

.ant-select-dropdown.member-select-popup {
  border-radius: 0;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 17px 86px 0px rgba(0, 0, 0, 0.13);
  margin-top: 7px;
  padding-left: 0;
  padding-right: 0;
}

.ant-select-dropdown.member-select-popup
  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: #fff;
}

.ant-select-dropdown.member-select-popup
  .ant-select-item.ant-select-item-option {
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}

.ant-select-dropdown.member-select-popup
  .ant-select-item.ant-select-item-option:last-child {
  border: 0;
}

.ant-btn.ant-btn-default.add-member-btn span {
  font-family: Roboto;
  font-weight: 500;
}

.ant-select.generic-select.ant-select-multiple .ant-select-selector {
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 0;
}

.ant-select.generic-select.ant-select-focused .ant-select-selector {
  border-color: #d9d9d9;
  box-shadow: none !important;
}

.ant-select.generic-select:hover .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-dropdown.generic-select-dropdown {
  padding: 5px 0;
  border-radius: 0;
  box-shadow: 0px 17px 86px 0px rgba(0, 0, 0, 0.13);
}

.ant-select-dropdown.generic-select-dropdown
  .ant-select-item.ant-select-item-option {
  border-radius: 0;
  border-bottom: 1px solid #d9d9d9;
}

.ant-select-dropdown.generic-select-dropdown
  .ant-select-item.ant-select-item-option:last-child {
  border: none;
}

.ant-select-dropdown.generic-select-dropdown
  .ant-select-item.ant-select-item-option
  .ant-select-item-option-content {
  font-size: 12px;
  font-family: Roboto;
  color: #555;
}

.ant-select.generic-select.ant-select-multiple
  .ant-select-selection-item
  span.ant-select-selection-item-content {
  font-size: 12px;
  font-family: 'Roboto';
  color: #555;
  line-height: initial;
  height: fit-content !important;
}

.ant-select.generic-select.ant-select-multiple .ant-select-selection-item {
  align-items: center;
}

.invite-form-field.ant-input {
  padding: 8.5px 10px;
}

.invite-form-field.ant-input:hover {
  border-color: #d9d9d9;
}

.invite-form-field.ant-input::placeholder {
  font-size: 12px;
  color: #9d9d9d;
  font-family: Roboto;
}

.invite-form-field.ant-input:focus {
  box-shadow: none;
}

.ant-btn.invite-form-btn {
  border: 1px solid rgb(217, 217, 217);
  width: auto;
  height: auto;
  padding: 6.3px 11px;
}

.ant-avatar {
  background-color: rgb(237, 245, 252);
  color: #434343;
  font-size: 11px;
  font-family: Roboto;
  font-weight: 400px;
}

.auth-app-logo {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 9rem;
  height: auto;
}

/* Project Apps styles */

.ant-tooltip.project-apps-overlay {
}

.ant-tooltip.project-apps-overlay .ant-tooltip-inner {
  max-width: 20rem;
  padding: 1rem;
  height: 25rem;
  border-radius: 1rem;
  max-height: fit-content;
  width: 20rem;
}

.ant-tooltip.project-apps-overlay .app-div {
  width: 100%;
  padding: 10px 0;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.ant-tooltip.project-apps-overlay .app-div:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-tooltip.project-apps-overlay .app-icon svg {
  width: auto;
  height: 3rem;
}

.ant-tooltip.project-apps-overlay .ant-tooltip-content {
  width: fit-content;
}

.ant-select-generic-select .ant-select-selector:after {
  line-height: 32px !important;
}

.ant-select.generic-select.ant-select-multiple .ant-select-selector:after {
  line-height: 26px;
}

/* Project apps styles ends */

.ant-dropdown-trigger.add-operation {
  background-color: #fff;
  border: 1.5px solid #204464;
  padding: 10px 15px;
  border-radius: 0 !important;
  cursor: pointer;
}
