.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.txt-color-secondary {
  color: #9d9d9d;
}

/* Buttons styles */

button.generic-btn {
  width: fit-content;
  font-family: Roboto;
  font-size: 14px;
  padding: 10px 24px;
  height: auto;
  font-weight: 600;
  border-radius: 0rem;
  cursor: pointer;
}

button.generic-btn span {
  font-family: Roboto;
}

button.generic-btn.light,
button.generic-btn.light:hover {
  color: #204464 !important;
  border: 1.5px solid #204464 !important;
  background-color: #fff !important;
}

button.generic-btn.dark,
button.generic-btn:hover {
  background-color: #204464 !important;
  color: #fff !important;
  border: 1px solid #204464 !important;
}

button.generic-btn:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

/* Style for cards starts */

.cardTitle {
  color: #434343;
}

a.filter-dropdown {
  color: #434343;
  padding: 0px 13px;
}

a.filter-dropdown span.dropdownTitle {
  font-family: Roboto;
}

a.filter-dropdown {
  border: 1px solid #e9e9e9;
}

.dashboard-card .ant-card-head {
  border-bottom: 0px !important;
}

.piechart-title span {
  color: #7b7b7b;
}

.piechart .span.MuiSvgIcon-root {
  padding: 'auto';
}

.chart-right-border {
  border-right: 1px dashed #c4c4c4;
}

.chart-bottom-border {
  border-bottom: 1px dashed #c4c4c4;
}

.piechart-title-border {
  border-bottom: 1px dashed #c4c4c4;
}

.pieChartContainer .echarts-for-react {
  height: 180px !important;
}

.legend-name {
  color: #9d9d9d !important;
}

.legend-value {
  color: '#262626';
}

.analysis-card div {
  width: '20%';
}

.analysis-card-component {
  position: relative;
  width: '20%';
}

.linechart-title {
  color: #7b7b7b;
}

.arrow-outward-profit {
  color: #5ebf5b;
}

.arrow-outward-loss {
  color: #ea6f6f;
}

.analysisData-color {
  color: #434343;
}

.finance-card-titleOption {
  color: #204464;
}

/* Style for cards end  */

/* Dropdown styles */

.primary-dropdown {
  height: 100%;
  padding: 7px 13px !important;
  font-size: 10px !important;
}

.financePage-subheadings {
  color: #7b7b7b;
}

/* Dropdown styles ends */

/*Integration page styles starts*/

.integration-title-subtitle {
  color: #434343;
}

.integration-button {
  background-color: #204464;
  color: #ffffff;
  border: none;
}

.integration-content {
  color: #555555;
}

.link-image {
  width: 150px;
  height: auto;
}

/*Integration page styles starts*/

/*Logout modal styling starts*/

.confirm-logout-heading {
  color: #434343;
}

.cancel-btn {
  color: #555555;
  border: 1.5px solid #555555;
  background-color: white;
}

.logout-btn {
  color: white;
  background-color: #204464;
  border: 1px solid #204464;
}

.square-modal .ant-modal-content {
  border-radius: 0;
}

/*Logout modal styling ends*/

/* Header options styling starts */

.profile-name {
  color: #434343;
}

.org-name {
  color: #7b7b7b;
}

/* Header options styling ends */

/* Company styling starts */

.company-size {
  padding: 12px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.company-size.selected {
  border: 1px solid #204464;
}

/* Company styling end */

/* mapping page styles start  */
.mapping-header {
  color: #434343;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.ant-modal .ant-modal-content {
  border-radius: 0;
}

/* mapping page styles end  */

/* table styles start  */

.ant-table-wrapper .ant-table-column-title {
  z-index: 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  flex: none;
}

.ant-table-wrapper .ant-table-column-sorters {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}

.ant-table-wrapper .ant-table-column-sorter {
  color: #000000;
  width: 16px;
  /* height: 16px;  */
  /* flex-shrink: 0; */
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
  border-bottom: 1px solid #ebebeb;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #edf5fc;
}

/* table styles end  */

/*  manage app styles starts */

.action-button {
  background-color: #204464;
  padding: 2px 20px;
  color: #edf5fc;
  font-weight: 800;
}

/*  manage app styles end */

/* Individual filter buttons */

.individual-filters label.ant-radio-button-wrapper {
  border: 1px solid #9d9d9d;
  border-radius: 2px !important;
  margin-right: 12px;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  z-index: 0;
}

.individual-filters label.ant-radio-button-wrapper span {
  font-family: Roboto;
}

.individual-filters label.ant-radio-button-wrapper:hover,
.individual-filters
  label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #204464;
  background-color: #204464;
  color: #fff;
}

.individual-filters label.ant-radio-button-wrapper:before {
  display: none;
}

/* Projects page styles starts */

.operations-on-table {
  color: #7b7b7b;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500px !important;
  border: 0px;
  background-color: #f8fbff;
  padding-left: 0px;
}

.status-border {
  border: 1px solid #c4c4c4;
  border-radius: 2px;
}

.filter-headings {
  color: #3c3c3c;
}

.filter-values {
  color: #555555;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 0px !important;
  border: 1px solid #434343;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #204464;
}

.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: red;
}

.projectData {
  color: #555555;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  color: #555555;
}

.ant-dropdown .ant-dropdown-menu {
  padding: 0px !important;
}

.tasksData {
  color: #555555;
}

/* Projects page styles ends */

/* Modal Component styles */

.generic-modal.ant-modal {
}

.ant-modal.generic-modal .ant-modal-header .ant-modal-title {
  color: #555;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.ant-modal.generic-modal .ant-modal-content {
  padding: 2.25rem;
  box-shadow: none;
  max-height: 95vh;
  overflow: auto;
}

.ant-modal.generic-modal .ant-modal-content::-webkit-scrollbar {
  display: none;
}

/* Modal component styles ends  */

/* Context Menu styles */

.ant-dropdown.generic-context-menu-overlay ul.ant-dropdown-menu {
  padding: 0;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 2px 10px 0px rgba(133, 133, 133, 0.13);
}

.ant-dropdown.generic-context-menu-overlay
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item {
  border-bottom: 1px solid #ebebeb;
  padding: 0.85rem 1rem;
}

.ant-dropdown.generic-context-menu-overlay
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item:last-child {
  border: none;
}

.ant-dropdown.generic-context-menu-overlay
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item
  .context-menu-option
  svg.context-menu-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #9d9d9d;
  margin-right: 8px;
}

/* Context menu styles ends */

/* Styles for create-project modal starts */

.create-project-tabs .ant-tabs-nav::before {
  border-bottom: 1.5px solid #e9e9e9;
  width: fit-content;
}

.create-project-tabs .ant-tabs-nav {
  margin-bottom: 2rem;
}

.create-project-tabs .ant-tabs-tab {
  padding: 7px 10px;
}

.create-project-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #9797a6;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.create-project-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
  font-weight: 700;
}

.create-project-tabs .ant-tabs-ink-bar {
  background-color: black;
  /* width:'100%' !important */
}

.generic-image-upload
  .upload-image-div.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: fit-content !important;
}

.generic-image-upload
  .upload-image-div.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  height: 66px !important;
  width: 79px !important;
  background-color: #fff;
  border-radius: 0;
}

.generic-image-upload .upload-image-btn button.ant-btn:hover {
  color: #555555;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: fit-content !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  height: 66px !important;
  width: 79px !important;
}

.ant-upload-wrapper .ant-upload-drag {
  height: 66px !important;
}

.create-project-tabs .ant-tabs-nav {
  display: flex;
  width: 100% !important;
}

.create-project-headings {
  color: #555555;
}

.create-project-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab {
  width: 50%;
  text-align: center;
}

.create-project-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100% !important;
}

.create-project-tabs .ant-tabs-nav-wrap {
  width: 100%;
}

.create-project-tab-numbers {
  border-radius: 50%;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for create-project modal ends */

/* style for employee analysis starts */

.projects-involved-container {
  width: auto;
  border: 1px solid #d9d9d9;
  color: #434343;
  padding: 10px 14px 10px 14px;
}

.member-details-container {
  background-color: #fafafa;
}

.thread-layout {
  padding: 13px 20px 13px 20px;
  background-color: #f9f9f9;
  border: 0px;
}

/* style for employee analysis ends */

/* Roles Ui styles start */

/* .image-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .image-container img {
	display: block;
	transition: 0.3s; 
  }
  
  .image-container::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.25); 
	opacity: 0; 
	transition: 0.3s;
  }
  
  .image-container:hover img {
	opacity: 0.7;
  }
  
  .image-container:hover::before {
	opacity: 1; 
	cursor: pointer; 
	border-radius: 50%;
	width:25px;
	height: 25px;
  } */

.image-container:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: 0.3s;
}

/* Styles for manage-clients starts */

.my-clients-data {
  color: #555555;
}

/* Styles for manage-clients ends */

/* payee table style */
.expansion-table-name {
  color: #555555;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
}
.expansion-table-email {
  color: #555555;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
}
.payee-status {
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  background: rgba(230, 230, 230, 0.15);
  padding: 0.3rem 2rem;
}
